body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #000000;
}

h1, h2 {
  margin: 0;
  padding: 10px 0;
}

.App {
  text-align: center;
  padding: 20px;
}

button {
  margin: 10px;
}
